import type {ILoginResponse, IUser} from '@/interfaces/interfaces'
import client from '@/common/client'

interface ILogin {
    email: string
    password: string
}

const userRepository = {
    get: async (): Promise<IUser> => {
        return await client.get('/api/profile')
    },
    login: async (data: ILogin): Promise<ILoginResponse> => {
        return await client.post('/api/login', data)
    },
}

export default userRepository
