import { createRouter, createWebHistory } from "vue-router"
import LayoutView from "@/views/LayoutView.vue"
import LoginView from "@/views/LoginView.vue"
import { useStore } from "@/store"
import guard from "@/router/guard"
import requireAuth from "@/router/requireAuth"
import userRepository from "@/repositories/UserRepository"

interface IRoute {
    [string]: { path: string; name: string }
}

export const route: IRoute = {
    dashboard: { path: "", name: "dashboard" },
    cvList: {path: "/cvs", name: "cvs"},
    login: { path: "/login", name: "login" }
}

const auth = requireAuth(route.login.name, userRepository.get)

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            component: LayoutView,
            meta: {
                middleware: [auth]
            },
            children: [
                {
                    ...route.dashboard,
                    component: () => import("@/views/DashboardView.vue")
                },
                {
                    ...route.cvList,
                    component: () => import("@/views/CvListView.vue")
                }
            ]
        },
        {
            ...route.login,
            component: LoginView
        }
    ]
})

router.beforeEach(guard(useStore))

export default router
