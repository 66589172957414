import type {ILoginResponse} from '@/common/interfaces'
import {JWT_REFRESH_TOKEN, JWT_TOKEN} from '@/constants';

const tokenService = {
    jwt: {
        get: (): string | null => localStorage.getItem(JWT_TOKEN),
        has: (): boolean => !!localStorage.getItem(JWT_TOKEN)
    },
    refreshToken: {
        get: (): string | null => localStorage.getItem(JWT_REFRESH_TOKEN),
        has: (): boolean => !!localStorage.getItem(JWT_REFRESH_TOKEN)
    },
    setTokens: (data: ILoginResponse): void => {
        localStorage.setItem(JWT_TOKEN, data.token)
        localStorage.setItem(JWT_REFRESH_TOKEN, data.refreshToken)
    },
    removeTokens: (): void => {
        localStorage.removeItem(JWT_TOKEN)
        localStorage.removeItem(JWT_REFRESH_TOKEN)
    }
}

export default tokenService
