import axios from 'axios'
import tokenService from '@/services/TokenService'
import {DELAYED_WAIT} from '@/constants';
import type {ILoginResponse} from '@/interfaces/interfaces';

const timers = {}

const axiosInstance = axios.create({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

axiosInstance.interceptors.request.use((config) => {
    const {wait} = config

    if (wait) {
        const key = `${config.method}_${config.url}_${config.data.id ?? ''}`

        if (timers[key]) {
            clearTimeout(timers[key])
        }

        return new Promise((resolve) => {
            timers[key] = setTimeout(() => resolve(config), wait)
        })
    }

    return config
})

axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        const originalRequest = error.config
        const errMessage = error.response.data.message as string

        if (errMessage === 'Expired JWT Token' && !originalRequest._retry) {
            originalRequest._retry = true

            try {
                const refreshToken = tokenService.refreshToken.get()

                if (!refreshToken) {
                    throw new Error('Refresh token not found')
                }

                const data = await refreshAccessToken(refreshToken)
                tokenService.setTokens(data)
            } catch (refreshError) {
                tokenService.removeTokens()
                return Promise.reject(refreshError)
            }

            originalRequest.headers.Authorization = 'Bearer ' + tokenService.jwt.get()

            return axiosInstance(originalRequest)
        }

        if (error.response.status === 401 || error.response.status === 403) {
            tokenService.removeTokens()
        }

        return Promise.reject(error)
    }
)

const refreshAccessToken = async (refreshToken: string): Promise<ILoginResponse> => {
    const response = await axios.post(
        '/api/token/refresh',
        {refreshToken},
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    )

    return response.data
}

const getClientOptions = (wait = 0) => {
    const token = tokenService.jwt.get()

    if (token) {
        return {
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            wait
        }
    }

    return {wait}
}

const client = {
    get: async (url: string) => {
        const response = await axiosInstance.get(url, getClientOptions())
        return response.data
    },
    post: async (url: string, data: object = {}) => {
        const response = await axiosInstance.post(url, data, getClientOptions())
        return response.data
    },
    put: async (url: string, data: object) => {
        const response = await axiosInstance.put(url, data, getClientOptions())
        return response.data
    },
    patch: async (url: string, data: object, wait = false) => {
        const response = await axiosInstance.patch(
            url,
            data,
            getClientOptions(wait ? DELAYED_WAIT : 0)
        )
        return response.data
    },
    delete: async (url: string) => {
        const response = await axiosInstance.delete(url, getClientOptions())
        return response.data
    },
    download: async (url: string, fileName: string): Promise<void> => {
        const response = await axiosInstance.get(url, {
            ...getClientOptions(),
            responseType: 'blob'
        })

        const file = URL.createObjectURL(new Blob([response.data]))

        const docUrl = document.createElement('a')
        docUrl.href = file
        docUrl.setAttribute('download', fileName)
        document.body.appendChild(docUrl)
        docUrl.click()
        docUrl.remove()
    },
    upload: async (url: string, data: object = {}) => {
        const options = getClientOptions()
        options['headers']['Content-Type'] = 'multipart/form-data'

        const response = await axiosInstance.post(url, data, options)
        return response.data
    }
}

export default client
