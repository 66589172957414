import {createApp} from 'vue'
import {createPinia} from 'pinia'
import App from '@/App.vue'
import router from '@/router/router'
import {Quasar} from 'quasar'
import quasarLang from 'quasar/lang/ru'
import Vue3Toasity, {ToastContainerOptions} from 'vue3-toastify'

import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import 'quasar/src/css/index.sass'
import 'vue3-toastify/dist/index.css'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(Quasar, {
    plugins: {},
    lang: quasarLang
})
app.use(Vue3Toasity, {
    autoClose: 3000,
    position: 'top-center',
    closeButton: true
} as ToastContainerOptions)

app.mount('#app')
